<template>
  <a-drawer
    wrapClassName="task-drawer"
    width="38.5vw"
    :title="title"
    :visible="visible"
    @update:visible="(val) => $emit('update:visible', val)"
  >
    <div class="header">
      <div class="user">
        <div v-if="user">
          <div class="name">{{ user.fullName }}</div>
        </div>
      </div>
    </div>
    <div class="info" v-if="user">
      <div class="info-cell">
        Team: <span>{{ user.orgThreeTwo }}</span>
      </div>
      <div class="info-cell">
        Offering: <span>{{ user.orgFourOneOne }}</span>
      </div>
      <div class="info-cell">
        Grade: <span>{{ user.psGroup }}</span>
      </div>
      <div class="info-cell">
        GGID: <span>{{ user.ggid }}</span>
      </div>
    </div>
    <a-divider v-if="user" />
    <a-form layout="vertical" :model="formState">
      <a-form-item label="Note1" name="note1">
        <a-textarea
          style="width: 88%"
          placeholder="请输入"
          :rows="4"
          :maxlength="50"
          :disabled="readonly"
          showCount
          v-model:value="formState.note1"
          :auto-size="{ minRows: 4, maxRows: 4 }"
        />
      </a-form-item>
      <a-form-item label="Note2" name="note2">
        <a-textarea
          style="width: 88%"
          placeholder="请输入"
          :rows="4"
          :maxlength="50"
          :disabled="readonly"
          showCount
          v-model:value="formState.note2"
          :auto-size="{ minRows: 4, maxRows: 4 }"
        />
      </a-form-item>
      <a-form-item label="Note3" name="note3">
        <a-textarea
          style="width: 88%"
          placeholder="请输入"
          :rows="4"
          :maxlength="50"
          :disabled="readonly"
          showCount
          v-model:value="formState.note3"
          :auto-size="{ minRows: 4, maxRows: 4 }"
        />
      </a-form-item>
    </a-form>
    <div class="bottom-btn">
      <a-space v-if="!readonly">
        <a-button type="primary" ghost @click="handleCancel">取消</a-button>
        <a-button type="primary" @click="handleSave">保存</a-button>
      </a-space>
    </div>
  </a-drawer>
</template>

<script>
import {
  defineComponent,
  ref,
  onMounted
} from "vue";
import { useGlobalPropertyHook } from "@/hooks/common.js";
import {message} from "ant-design-vue";

export default defineComponent({
  name: "NotesDrawer",
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
    user: {
      type: Object,
      default: undefined,
    },
    notes: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props, { emit }) {
    const { $api } = useGlobalPropertyHook();
    const formState = ref({});
    const handleCancel = () => {
      emit("update:visible", false);
    };
    const handleSave = () => {
     $api.updateNote(formState.value).then((res)=>{
      if(res.code===200){
        message.success("保存成功！");
      }
      emit("update:visible", false);
      emit("update");
     })
    };
    onMounted(()=>{
      const {
        note1,
        note2,
        note3,
        idsNumber
      } = props.notes
      formState.value={
        note1,
        note2,
        note3,
        idNumber:idsNumber
      }
    })
    return {
      formState,
      handleCancel,
      handleSave,
    };
  },
});
</script>

<style scoped lang="less">
.task-drawer {
  .header {
    padding: 0 24px;
    display: flex;
    justify-content: space-between;
    .user {
      display: flex;
      align-items: center;
      font-size: 16px;
      line-height: 22px;
      .name {
        font-weight: 500;
        font-size: 24px;
        line-height: 34px;
        color: #505050;
      }
    }
  }
  .info {
    margin: 24px 0;
    padding: 24px;
    width: 100%;
    height: 108px;
    background: #fafafa;
    display: flex;
    flex-wrap: wrap;
    .info-cell {
      width: 50%;
      font-size: 16px;
      line-height: 22px;
      color: #2d3748;
      margin-bottom: 16px;
      span {
        margin-left: 16px;
        color: #999;
      }
    }
  }
  .forms {
    display: flex;
    .form {
      flex: 1;
      margin-right: 100px;
      .item {
        width: 240px;
      }
    }
  }
}
</style>
