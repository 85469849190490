<template>
  <div class="mb12 mt12 ml12 mr12 titleHeader">
    <div>
      <span class="goback underline" @click="backRmBoard">
        返回上一级</span>
    </div>
  </div>
  <section class="app-container" ref="staffInfoRef">
    <section>
      <div>
        <FilterLayout
            @doReset="resetForm"
            @doSearch="queryDoSearch"
            @doCollapseEffect="getSize"
            ref="filterLayoutRef"
            @showMoreForm="showMoreForm"
            :moreBtnVisible="moreBtnVisible"
            :isShowMoreFlag="isShowMoreList"
        >
          <template #customform>
            <a-form
                ref="formRef"
                class="form rms-form"
                layout="vertical"
                :model="formState"
            >
              <template v-for="(item,index) in queryList" :key="index">
                <template v-if="index<=3">
                  <a-form-item :label="item.code" name="name" class="rms-form-item" :class="item.inputDataType==='assignmentType'||'availability'?'explainIcon':''">
                    <template v-if="item.type==='2'">
                      <a-select
                          v-model:value="formState[item.inputDataType]"
                          :options="getOptions(item.inputDataType)"
                          placeholder="请选择"
                          allowClear
                          show-search
                      >
                      </a-select>
                    </template>
                    <template v-if="item.type==='3'">
                      <a-input
                          v-model:value="formState[item.inputDataType]"
                          placeholder="Name/GGID"
                          allowClear
                          autocomplete="off"
                      />
                    </template>
                    <template v-if="item.type==='4'">
                      <a-radio-group
                          v-model:value="formState[item.inputDataType]"
                          button-style="solid"
                          class="btnRadio"
                      >
                        <a-radio-button
                            :value="item.value"
                            v-for="(item, index) in getOptions(item.inputDataType)"
                            :key="index"
                        >{{ item.label }}</a-radio-button>
                      </a-radio-group>
                    </template>
                    <template v-if="item.type==='5'">
                      <SelectWithAll
                          v-model:value="formState[item.inputDataType]"
                          placeholder="请选择"
                          mode="multiple"
                          showArrow
                          :maxTagCount="1"
                          allowClear
                          :options="getOptions(item.inputDataType)"
                          @change="getSelectChange(item.inputDataType)"
                          :tag-count="item.inputDataType==='gradeList'?3:1"
                      >
                      </SelectWithAll>
                    </template>
                    <template v-if="item.type==='6'">
                      <a-range-picker
                          v-model:value="formState.dateValueList"
                          :placeholder="['Start date', 'End date']"
                          format="YYYY-MM-DD"
                          @change="handleDateChange"
                      />
                    </template>
                  </a-form-item>
                </template>
              </template>
              <MoreFormList :visible="isShowMoreList" @open="openFormSortBox" @close="closeMoreForm" @moreFormSearch="moreSeachHanlder">
                <template v-for="(item,index) in queryList" :key="index">
                  <template v-if="index>3">
                    <a-form-item name="name" class="rms-form-item">
                      <template v-slot:label v-if="item.type==='2'&&(item.inputDataType==='assignmentType'||item.inputDataType==='availability')">
                        <span>{{ item.code }}</span>
                        <a-popover placement="topLeft">
                          <QuestionCircleOutlined :style="{ color: '#3182ce' }"/>
                          <template #content>
                            <a-table :columns="item.inputDataType==='assignmentType'?assignmentColumns:availabilityColumns" :data-source="item.inputDataType==='assignmentType'?assignmentData:availabilityData" :pagination="false"></a-table>
                          </template>
                        </a-popover>
                      </template>
                      <template v-slot:label v-else>
                        <span>{{ item.code }}</span>
                      </template>
                      <template v-if="item.type==='2'">
                        <a-select
                            v-model:value="formState[item.inputDataType]"
                            :options="getOptions(item.inputDataType)"
                            placeholder="请选择"
                            allowClear
                            show-search
                        >
                        </a-select>
                      </template>
                      <template v-if="item.type==='3'">
                        <a-input
                            v-model:value="formState[item.inputDataType]"
                            placeholder="Name/GGID"
                            allowClear
                            autocomplete="off"
                        />
                      </template>
                      <template v-if="item.type==='4'">
                        <a-radio-group
                            v-model:value="formState[item.inputDataType]"
                            button-style="solid"
                            class="btnRadio"
                        >
                          <a-radio-button
                              :value="item.value"
                              v-for="(item, index) in getOptions(item.inputDataType)"
                              :key="index"
                          >{{ item.label }}</a-radio-button>
                        </a-radio-group>
                      </template>
                      <template v-if="item.type==='5'">
                        <SelectWithAll
                            v-model:value="formState[item.inputDataType]"
                            placeholder="请选择"
                            mode="multiple"
                            showArrow
                            :maxTagCount="1"
                            allowClear
                            :options="getOptions(item.inputDataType)"
                            @change="getSelectChange(item.inputDataType)"
                            :tag-count="item.inputDataType==='gradeList'?3:1"
                        >
                        </SelectWithAll>
                      </template>
                      <template v-if="item.type==='6'">
                        <a-range-picker
                            v-model:value="formState.dateValueList"
                            :placeholder="['Start date', 'End date']"
                            format="YYYY-MM-DD"
                            @change="handleDateChange"
                        />
                      </template>
                    </a-form-item>
                  </template>
                </template>
              </MoreFormList>
            </a-form>
          </template>
          <template #operationBtns>
            <div class="buttons-wrap right">
              <a-dropdown :getPopupContainer="()=>$refs.staffInfoRef" :trigger="['click']" :placement="'bottomRight'" overlayClassName="dropdown">
                <a-button type="primary" ghost @click.prevent>More</a-button>
                <template #overlay>
                  <a-menu>
                    <a-menu-item key="0" v-auth="['system:staffInfo:resumeupdates']" :disabled="rowlist.length===0" @click="toDrawerVisible">
                      <CloudUploadOutlined class="icon-font" />简历更新提醒
                    </a-menu-item>
                    <a-menu-item key="1" v-auth="['system:staffInfo:welcomeletter']" :disabled="rowlist.length===0" @click="towelcome">
                      <MailOutlined class="icon-font" />发送 Welcome Letter
                    </a-menu-item>
                    <a-menu-item key="2" v-auth="['system:staffBoard:download']" :disabled="rowlist.length===0" @click="downloadClick" >
                      <DownloadOutlined class="icon-font" />批量下载简历
                    </a-menu-item>
                  </a-menu>
                </template>
              </a-dropdown>
              <Tooltip title="导出">
                <a-button class="ml12" v-auth="['system:staffBoard:export']" type="primary" ghost @click="exportClick">
                  <ExportOutlined />
                </a-button>
              </Tooltip>
            </div>
          </template>
        </FilterLayout>
      </div>
      <section class="mt12 table-wrap">
        <a-table :dataSource="dataList" :columns="columns" :pagination="pagination" :scroll="{
          x: 3600,
          y: tableHeight,
        }" :rowKey="(record) => record.staffId" :row-selection="rowSelection" @change="handleTableChange">
          <template #assignmentType="{ record }">
            <span v-for="item in record.assignmentType?.split(',') || []" :key="item" class="assignment-icon"
              :title="record.assignmentType">{{ item[0] }}</span>
          </template>
          <template #taskType="{ record: { taskType = '' } }">
            <template v-if="taskType">
              <span class="task-icon" v-for="item in taskType.split(',')" :key="item"
                :style="{ background: taskTypeMap[item].color }" :title="taskTypeMap[item].label"></span>
            </template>
          </template>
          <template #action="{ record }">
            <ActionBtn
                v-bind="record"
                :btnList="btnList"
                @FormOutlined="handleNotesEdit(record)"
                @AppstoreAddOutlined="handleOpenTaskList(record)"
                @SolutionOutlined="handleAddTask(record)"
                @IdcardOutlined="handleOpenEmpInfo(record)"
            />
          </template>
        </a-table>
      </section>
    </section>
    <FormModalBox v-model:visible="isShowFormSortBox" :checkList="checkList" @closeModal="closeModal" @reset="queryFormList"></FormModalBox>
  </section>
  <!--  :userDataList="userDataList"-->
  <emp-info v-if="isShowingEmpInfo" :isShowingEmpInfo="isShowingEmpInfo" :getId="getId" @close-emp-info="closeEmpInfo"
    :showworkExperienceCol="showworkExperienceCol" />
  <TaskDrawer v-if="taskDrawerVisible" title="添加任务" :task="taskData" :user="taskData" :userId="taskData.staffId"
    @update="doSearch" v-model:visible="taskDrawerVisible" />
  <WelcomeLetter v-if="welcomeVisible" title="Welcome Letter" :rowlist="rowlist" @handleCancel="handleWelcomeCancel"
    @update="doSearch" v-model:visible="welcomeVisible" />
  <Drawer v-if="DrawerVisible" title="简历更新提醒" :rowlist="rowlist" @handleCancel="handleCancel" @update="doSearch"
    v-model:visible="DrawerVisible" />
  <NotesDrawer
    v-if="notesDrawerVisible"
    title="Notes编辑"
    :notes="notesData"
    :user="notesData"
    @update="doSearch"
    v-model:visible="notesDrawerVisible"
  />
</template>

<script>
import {
  defineComponent,
  onMounted,
  reactive,
  ref,
  nextTick,
  onUnmounted,
  toRefs,
  computed,
  watch
} from "vue";
import { useGlobalPropertyHook } from "@/hooks/common.js";
import {dictionaryOpt, dictionaryOptions, useTableHeight} from "@/utils/common.js";
import { ExportOutlined,QuestionCircleOutlined,CloudUploadOutlined,MailOutlined,DownloadOutlined } from "@ant-design/icons-vue";
import EmpInfo from "@/components/EmpInfo.vue";
import { downloadFile,handleJtpFilter } from "@/utils/common";
import { message } from "ant-design-vue";
import { useRouter, useRoute } from "vue-router";
import TaskDrawer from "../components/TaskDrawer";
import NotesDrawer from "../components/NotesDrawer";
import {cloneDeep, debounce} from "lodash";
import {  getValueAndOps  } from "@/utils/orgSelectData";
import WelcomeLetter from '@/components/WelcomeLetter.vue';
import Drawer from './componets/Drawer.vue';
import ActionBtn from '@/components/ActionBtn'
import MoreFormList from '@/components/MoreFormList'
import FormModalBox from '@/components/FormModalBox'
import Tooltip from '@/components/Tooltip'
import store from "@/store";

const btnList = localStorage.getItem('switchRoleName')==='RM'?[
  {
    name: 'Notes 编辑',
    icon: 'FormOutlined',
    auth: ['system:staffInfo:notesedit']
  },
  {
    name: '个人信息',
    icon: 'IdcardOutlined',
    auth: ['system:staffInfo:personInformation']
  },
  {
    name: '添加任务',
    icon: 'SolutionOutlined',
    auth: ['system:staffInfo:addTask']
  },
  {
    name: '任务列表',
    icon: 'AppstoreAddOutlined',
    auth: ['system:staffInfo:taskList']
  },
]:[
  {
    name: '个人信息',
    icon: 'IdcardOutlined',
    auth: ['system:staffInfo:personInformation']
  },
  {
    name: '添加任务',
    icon: 'SolutionOutlined',
    auth: ['system:staffInfo:addTask']
  },
  {
    name: '任务列表',
    icon: 'AppstoreAddOutlined',
    auth: ['system:staffInfo:taskList']
  },
]
const columns = [
  {
    title: "GGID",
    dataIndex: "ggid",
    ellipsis: true,
    width: 150,
    fixed: "left",
  },
  {
    title: "Name",
    dataIndex: "fullName",
    ellipsis: true,
    width: 150,
    fixed: "left",
  },
  {
    title: "Email",
    dataIndex: "email",
    ellipsis: true,
    width: 200,
  },
  {
    title: "Grade",
    dataIndex: "psGroup",
    ellipsis: true,
    width: 80,
  },
  {
    title: "Status",
    dataIndex: "status",
    ellipsis: true,
    width: 120,
  },
  {
    title: "Assignment Type",
    dataIndex: "assignmentType",
    width: 160,
    ellipsis: true,
    slots: { customRender: "assignmentType" },
  },
  {
    title: "Task Type",
    dataIndex: "taskType",
    width: 100,
    ellipsis: true,
    slots: { customRender: "taskType" },
  },
  {
    title: "Availability",
    dataIndex: "availability",
    width: 130,
    ellipsis: true,
  },
  {
    title: "Team",
    dataIndex: "orgThreeTwo",
    width: 150,
    ellipsis: true,
  },
  {
    title: "Offering",
    dataIndex: "orgFourOneOne",
    ellipsis: true,
    width: 200,
  },
  {
    title: "Base Location",
    dataIndex: "workLocation",
    width: 150,
    ellipsis: true,
  },
  {
    title: "Capgemini Start Date",
    dataIndex: "onboardDate",
    width: 200,
    ellipsis: true,
  },
  {
    title: "Termination Date",
    dataIndex: "terminationDate",
    width: 180,
    ellipsis: true,
  },
  {
    title: "Role",
    dataIndex: "position",
    width: 150,
    ellipsis: true,
  },
  {
    title: "简历最近一次更新时间",
    dataIndex: "cvUpdateDate",
    ellipsis: true,
    width: 180,
  },
  {
    title: "简历最近一次提醒时间",
    dataIndex: "cvReminderDate",
    ellipsis: true,
    width: 180,
  },
  {
    title: "简历数量",
    dataIndex: "cvNumber",
    ellipsis: true,
    width: 100,
  },
  {
    title: "Welcome Letter 发送时间",
    dataIndex: "sendmailDate",
    width: 200,
    ellipsis: true,
    sorter: true,
  },
  {
    title: "Note1",
    dataIndex: "note1",
    width: 120,
    ellipsis: true,
  },
  {
    title: "Note2",
    dataIndex: "note2",
    width: 120,
    ellipsis: true,
  },
  {
    title: "Note3",
    dataIndex: "note3",
    ellipsis: true,
    width: 120,
  },
  {
    title: "操作",
    dataIndex: "action",
    slots: { customRender: "action" },
    fixed: "right",
    width: localStorage.getItem('switchRoleName')==='RM'?120:100,
  },
];
const availabilityColumns = [
  {
    title: 'Availability',
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: '字段解释',
    dataIndex: 'explain',
    key: 'explain'
  },
]

const availabilityData = [
  { name: 'Partial Bench', explain: '人员当天的调令+task (Shadow/on project support/transition/ COE/management/ No Pay Leave/ Maternity Leave)小时数相加大于0小于等于4小时' },
  { name: 'Unavailable', explain: '人员当天的调令+task (Shadow/on project support/transition/ COE/management/ No Pay Leave/ Maternity Leave)小时数相加大于4小于等于8小时' },
  { name: 'Fully-bench', explain: '人员当天没有正在进行中的调令+task (Shadow/on project support/transition/ COE/management/ No Pay Leave/ Maternity Leave)' },
]

const assignmentColumns = [
  {
    title: '调令类型',
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: '字段解释',
    dataIndex: 'explain',
    key: 'explain'
  },
]

const assignmentData = [
  { name: 'Internal', explain: '行业线选择为others的调令' },
  { name: 'Billable', explain: '行业线选择为非others的调令' },
]

export default defineComponent({
  name: "staffInfo",
  components: {
    Tooltip,
    //QuestionCircleOutlined,
    EmpInfo,
    TaskDrawer,
    NotesDrawer,
    WelcomeLetter,
    Drawer,
    ExportOutlined,
    QuestionCircleOutlined,
    CloudUploadOutlined,
    MailOutlined,
    DownloadOutlined,
    ActionBtn,
    MoreFormList,
    FormModalBox
  },

  setup() {
    const { $api } = useGlobalPropertyHook();
    const router = useRouter();
    const route = useRoute();
    const dataList = ref([]);
    const skillNameOps = ref([]);
    const staffGradeOps = ref([]);
    const assignmentTypeOps = ref([]);
    const availabilityOps = ref([]);
    const positionStatusOps = ref([]);
    const taskTypeOps = ref([]);
    const taskTypeMap = ref({});
    const formRef = ref(null);
    const tableHeight = ref(0);
    const isShowingEmpInfo = ref(false);
    const getId = ref("");
    // const userDataList = reactive({});
    const isShowCollapseBtn = ref(false);
    const show = ref(false);
    const statusOps = ref([]);
    const roleOps = ref([]);
    let withJtpGradeOps;
    let withoutJtpGradeOps;
    const orgOpsObj = reactive({
      orgTwoOps: [],
      orgFiveOneOps: [],
      orgThreeTwoOps: [],
      orgFourOneOneOps: []
    });
    const formState = reactive({
      condition: undefined,
      orgFourOneOneList: [],
      orgThreeTwoList: [],
      statusList: [],
      skillName: [],
      gradeList: [],
      assignmentType: undefined,
      availability: undefined,
      taskTypeList: [],
      jobStatus: "1",
      withJTP: '0',
      roleList: [],
      orgTwoList: [],
      orgFiveOneList: [],
      dateValueList: [],
      onBoardBegin: '',
      onBoardEnd: ''
    });
    const handleDateChange = () => {
      formState.onBoardBegin = formState.dateValueList[0]
      formState.onBoardEnd = formState.dateValueList[1]
    }
    //welcome letter抽屉开关
    const welcomeVisible = ref(false)
    const welcomedisabled = ref(true)
    const rowlist = ref([])
    const towelcome = () => {
      // rowlist.value = state.selectedRows
      welcomeVisible.value = true
    }
    const handleWelcomeCancel = () => {
      welcomeVisible.value = false
    }
    let currentState = cloneDeep(formState);
    const prevState = {
      selectedRowKeys: [],
      selectedRows: [],
    };
    const state = reactive({
      selectedRowKeys: [],
      selectedRows: [],
    });

    const radioOptions = ref([
      { label: 'With JTP', value: '1' },
      { label: 'Without JTP', value: '0' },
    ]);

    const hasSelected = computed(() => state.selectedRowKeys.length <= 40);

    const handleClickRow = () => {
      state.selectedRowKeys = [];
      state.selectedRows = [];
    };

    const rowSelection = {
      selectedRowKeys: toRefs(state).selectedRowKeys,
      // getCheckboxProps: (record) => {
      //   return {
      //     disabled: record.cvStatus === "0"
      //   };
      // },
      onSelect: (record, selected) => {
        if (selected) {
          rowlist.value.push(record)
        } else {
          const delIndex = rowlist.value.findIndex((val) => {
            return val.staffId === record.staffId
          })
          rowlist.value.splice(delIndex, 1)
        }
      },
      onChange: (selectedRowKeys, selectionRows) => {
        if(selectedRowKeys.length===dataList.value.length){
          rowlist.value=cloneDeep(dataList.value)
        }else if(selectedRowKeys.length===0){
          rowlist.value=[]
        }
        state.selectedRowKeys = selectedRowKeys;
        state.selectedRows = selectionRows;
        nextTick(() => {
          document
            .getElementsByClassName(".ant-checkbox-input")[0]
            ?.addEventListener("click", handleClickRow);
        });
        if (!hasSelected.value) {
          state.selectedRowKeys = prevState.selectedRowKeys;
          state.selectedRows = prevState.selectedRows;
          message.warning("最多只能下载40个人的简历");
        } else {
          prevState.selectedRowKeys = state.selectedRowKeys;
          prevState.selectedRows = state.selectedRows;
        }
      },
    };

    const downloadClick = () => {
      $api.downLoadAllResume(state.selectedRowKeys).then((res) => {
        if(res.msg === '您当前选择的顾问均没有简历，无法下载') {
          message.error(res.msg)
        } else {
          message.success(res.msg)
        }
      });
      //判断文件中转站是否有未读消息
      store.dispatch("getIsFileMessage");
      state.selectedRowKeys = [];
      state.selectedRows = [];
      rowlist.value = []
    };

    //页脚
    const pagination = ref({
      current: 1,
      pageSize: 12,
      pageSizeOptions: ['12','20','30','40'],
      total: 0,
      showSizeChanger: true,
      showTotal: (total) => {
        return `共 ${total} 条`;
      },
      onChange: (page) => {
        pagination.value.current = page;
        doSearch(pagination.value);
      },
      onShowSizeChange: (_, size) => {
        pagination.value.pageSize = size;
        pagination.value.current = 1;
        doSearch(pagination.value);
      },
    });
    const getSelectOptions = () => {
      //数据字典
      $api
        .getDictionaryList({
          params: {
            dictType: "grade,skillName,assignmentType,availability,staffStatus,jobStatus,role",
          },
        })
        .then((res) => {
          roleOps.value = dictionaryOptions(res.data.role)

          // staffGradeOps.value = createSelectOptionData(res.data.grade);
          withJtpGradeOps = cloneDeep(createSelectOptionData(res.data.grade))
          withoutJtpGradeOps = handleJtpFilter(withJtpGradeOps)
          if (withoutJtpGradeOps && withJtpGradeOps) {
            staffGradeOps.value = formState.withJTP === '0' ? withoutJtpGradeOps : withJtpGradeOps
          }
          skillNameOps.value = createSelectOptionData(res.data.skillName,'special');
          assignmentTypeOps.value = createSelectOptionData(res.data.assignmentType);
          availabilityOps.value = createSelectOptionData(res.data.availability);
          statusOps.value = createSelectOptionData(res.data.staffStatus);
          positionStatusOps.value = createSelectOptionData(res.data.jobStatus);
          /*assignmentTypeOps.value = (res.data?.assignmentType || []).map(
            ({ dictValue, dictCode }) => ({ value: dictCode, label: dictValue })
          );
          availabilityOps.value = (res.data?.availability || []).map(
            ({ dictValue, dictCode }) => ({ value: dictCode, label: dictValue })
          );
          statusOps.value = (res.data?.staffStatus || []).map(({ dictCode }) => dictCode);
          positionStatusOps.value = (res.data?.jobStatus || []).map(
            ({ dictValue, dictCode }) => ({ value: dictCode, label: dictValue })
          );*/
        });
    };

    const getOrgList = () => {
      $api.getOrgTwo_Three_Four_FiveList().then((res) => {
        const { orgFourOneOneList, orgTwoList, orgFiveOneList, orgThreeTwoList } = res.data;
        orgOpsObj.orgTwoOps = dictionaryOpt(orgTwoList);
        orgOpsObj.orgFiveOneOps = dictionaryOpt(orgFiveOneList);
        orgOpsObj.orgThreeTwoOps = dictionaryOpt(orgThreeTwoList);
        orgOpsObj.orgFourOneOneOps = dictionaryOpt(orgFourOneOneList);
      })
      //查询org3.2/org4.2
      /*$api.getOrgThree_FourList().then((res) => {
        orgThreeTwoOps.value = handleSelectOrg(res.data.orgThreeTwo);
        orgFourOneOneOps.value = handleSelectOrg(res.data.orgFourOneOne);
      });*/
    };
    const filterItemsBtn = () => {
      getSize()
    };
    const handleFilter = () => { }
    const getSize = () => {
      nextTick(() => {
        tableHeight.value = useTableHeight();
      });
    };

    window.addEventListener("resize", getSize);

    //查询
    const doSearch = ({ current = 1 } = {}) => {
      $api
        .getRmPersonList({
          ...currentState,
          pageIndex: current,
          pageSize: pagination.value.pageSize,
          sort
        })
        .then((res) => {
          dataList.value = res.data.resource;
          pagination.value.total = res.data.total;
          pagination.value.current = res.data.pageIndex + 1;
          pagination.value.pageSize = res.data.pageSize;
        });
    };

    const queryDoSearch = () => {
      currentState = cloneDeep(formState);
      rowlist.value=[]
      state.selectedRowKeys=[]
      state.selectedRows=[]
      doSearch()
    }

    //options value
    const createSelectOptionData = (data,type = 'true') => {
      const options = data.map((item) => {
        return {
          label: item.dictValue,
          value: type == 'special' ? item.dictValue : item.dictCode,
          key: item.dictId,
        };
      });
      return options;
    };

    /*const handleSelectOrg = (data) => {
      const options = data.map((item) => {
        return {
          value: item,
          label: item,
        };
      });
      return options;
    };*/
    //重置
    const resetForm = () => {
      route.query.status = [];
      route.query.orgTwoList = undefined;
      route.query.orgFiveOneList = undefined;
      route.query.gradeList = undefined;
      route.query.roleList = undefined;
      route.query.orgThreeTwoList = undefined;
      route.query.orgFourOneOneList = undefined;
      route.query.taskType = undefined;
      Object.assign(formState, {
        condition: undefined,
        orgFourOneOneList: [],
        orgThreeTwoList: [],
        statusList: [],
        skillName: [],
        gradeList: [],
        assignmentType: undefined,
        availability: undefined,
        taskTypeList: [],
        jobStatus: "1",
        withJTP: '0',
        roleList: [],
        orgTwoList: [],
        orgFiveOneList: [],
        dateValueList: [],
        onBoardBegin: '',
        onBoardEnd: ''
      });
      currentState = cloneDeep(formState)
      rowlist.value=[]
      state.selectedRowKeys=[]
      state.selectedRows=[]
      doSearch();
      getOrgList();
    };

    //打开顾问信息Modal
    const handleOpenEmpInfo = (record) => {
      getId.value = record.staffId;
      isShowingEmpInfo.value = true;
      /*$api.getOneUser(record.staffId).then((res) => {
        Object.assign(userDataList, res.data);
        if (res.code == 200) {
          isShowingEmpInfo.value = true;
        }
      });*/
    };

    //关闭顾问信息弹窗
    const closeEmpInfo = () => {
      isShowingEmpInfo.value = false;
    };

    const backRmBoard = () => {
      router.push("/staffBoard");
    };

    const filterOption = (input, option) => {
      return option.value.toUpperCase().indexOf(input.toUpperCase()) >= 0;
    };

    const getTaskType = () => {
      $api.getWorkTaskComboBox().then((res) => {
        taskTypeOps.value = (res.data?.taskType || []).map(
          ({ dictCode, dictValue, dictRemark }) => {
            taskTypeMap.value[dictCode] = {
              color: dictRemark,
              value: dictCode,
              label: dictValue,
            };
            return {
              value: dictCode,
              label: dictValue,
            };
          }
        );
      });
    };

    const readQueryFromBoard = () => {
      Object.assign(formState, {
        condition: undefined,
        orgFourOneOneList: [],
        orgThreeTwoList: [],
        statusList: [],
        skillName: [],
        gradeList: [],
        assignmentType: undefined,
        availability: undefined,
        taskTypeList: [],
        jobStatus: "1",
        roleList: [],
        orgTwoList: [],
        orgFiveOneList: [],
        dateValueList: [],
        onBoardBegin: '',
        onBoardEnd: ''
      });
      // formState.status = route.query.status;
      formState.orgThreeTwoList = route.query.orgThreeTwoList
        ? route.query.orgThreeTwoList?.split(",")
        : [];
      formState.orgFourOneOneList = route.query.orgFourOneOneList
        ? route.query.orgFourOneOneList?.split(",")
        : [];
      formState.taskTypeList = route.query.taskType
        ? route.query.taskType?.split(",")
        : [];
      formState.statusList = route.query.status
        ? route.query.status?.split(",")
        : [];
      formState.orgTwoList = route.query.orgTwoList
        ? route.query.orgTwoList?.split(",")
        : [];
      formState.orgFiveOneList = route.query.orgFiveOneList
        ? route.query.orgFiveOneList?.split(",")
        : [];
      formState.gradeList = route.query.gradeList
        ? route.query.gradeList?.split(",")// @todo
        : [];
      formState.roleList = route.query.roleList
        ? route.query.roleList?.split(",")
        : [];
      formState.availability = route.query.availability || undefined;
      formState.assignmentType = route.query.assignmentType || undefined;
      formState.withJTP = route.query.withJTP || '0';
      currentState = cloneDeep(formState);
      doSearch();
    }

    const queryList = ref([])
    const checkList = ref([])
    const moreBtnVisible = ref(false)
    const queryFormList = (data) => {
      $api.queryFilterSort({
        firstMenu: 'resourceManagement',
        secondMenu: 'staffList',
        isQueryDefault: data.isQueryDefault
      }).then((res) => {
        if(data.isQuery){
          queryList.value = res.data.filter((item)=>{
            return item.isDeleted==='0'
          })
          queryList.value.length > 4?moreBtnVisible.value = true :moreBtnVisible.value = false
          checkList.value=[...queryList.value]
        }else{
          checkList.value = res.data.filter((item)=>{
            return item.isDeleted==='0'
          })
        }
      });
    };
    const getOptions = (data)=> {
      switch (data) {
        case 'orgTwoList':
          return orgOpsObj.orgTwoOps
        case 'orgThreeTwoList':
          return orgOpsObj.orgThreeTwoOps
        case 'orgFiveOneList':
          return orgOpsObj.orgFiveOneOps
        case 'orgFourOneOneList':
          return orgOpsObj.orgFourOneOneOps
        case 'jobStatus':
          return positionStatusOps.value
        case 'gradeList':
          return staffGradeOps.value
        case 'skillName':
          return skillNameOps.value
        case 'roleList':
          return roleOps.value
        case 'availability':
          return availabilityOps.value
        case 'statusList':
          return statusOps.value
        case 'assignmentType':
          return assignmentTypeOps.value
        case 'withJTP':
          return radioOptions.value
        case 'taskTypeList':
          return taskTypeOps.value
        default :
          ''  // 如果没
      }
    }
    const getSelectChange = debounce((data)=> {
      switch (data) {
        case 'orgTwoList':
          return handleOrgChange('orgTwo')
        case 'orgThreeTwoList':
          return handleOrgChange('orgThreeTwo')
        case 'orgFourOneOneList':
          return handleOrgChange('orgFourOneOne')
        case 'orgFiveOneList':
          return handleOrgChange('orgFiveOne')
        default :
          ''  // 如果没
      }
    },500)
    const isShowMoreList = ref(false)
    const isShowFormSortBox = ref(false)
    const showMoreForm = (data) => {
      isShowMoreList.value = data
    }
    const openFormSortBox = () => {
      isShowFormSortBox.value = true
    }
    const closeMoreForm = (data) => {
      if(data){
        formState.positionId = undefined
        formState.lineManager = ''
        formState.reviewTime = []
      }
      isShowMoreList.value = false
    }
    const closeModal = ()=> {
      isShowFormSortBox.value = false
    }
    onMounted(() => {
      getSelectOptions();
      getSize();
      getOrgList();
      getTaskType();
      readQueryFromBoard();
      getOrgRelatedList();
      setTimeout(queryFormList({isQueryDefault:0,isQuery: true}),500)
    });

    onUnmounted(() => {
      window.removeEventListener("resize", getSize);
    });
    const handleOrgChange = (type) => {
      const formOrgData = {
        orgTwoList: formState.orgTwoList,
        orgFiveOneList: formState.orgFiveOneList,
        orgThreeTwoList: formState.orgThreeTwoList,
        orgFourOneOneList: formState.orgFourOneOneList
      }
      const { orgsValue, orgOpsList } = getValueAndOps(orgAllList.value, formOrgData, orgOpsObj, type)
      Object.assign(formState, orgsValue)
      Object.assign(orgOpsObj, orgOpsList)
    };
    const orgAllList = ref([])
    const getOrgRelatedList = () => {
      $api.getAllOrgRelatedList().then((res) => {
        orgAllList.value = res.data
      })
    }
    const taskData = ref({});
    const taskDrawerVisible = ref(false);
    const DrawerVisible = ref(false);
    const handleOpenTaskList = (record) => {
      router.push({
        path: "/resource/task",
        query: {
          idNumber: record.idsNumber,
          jobStatus: formState.jobStatus,
        },
      });
    };
    const handleAddTask = (record) => {
      taskData.value = record;
      taskDrawerVisible.value = true;
    };
    const notesData=ref({})
    const notesDrawerVisible=ref(false)
    const handleNotesEdit = (record) => {
      notesData.value = record;
      notesDrawerVisible.value = true;
    };

    //简历抽屉关闭
    const handleCancel = () => {
      DrawerVisible.value = false
    }
    const toDrawerVisible = () => {
      rowlist.value = state.selectedRows
      console.log(rowlist.value);
      DrawerVisible.value = true
    }
    const exportClick = () => {
      $api.downloadStaffInfo({
        ...currentState
      }).then((res) => {
        downloadFile(res.file, res.filename);
      });
    };
    const sort = reactive({
      order: '',
      field: ''
    });
    const moreSeachHanlder = () => {
      queryDoSearch()
      closeMoreForm()
    }
    const handleTableChange = (pag, filters, sorter) => {
      if (!sorter.order) {
        sorter.order = ""
        sorter.field = ""
      }
      Object.assign(sort, sorter)
      $api
        .getRmPersonList({
          ...currentState,
          pageIndex: pag.current,
          pageSize: pagination.value.pageSize,
          sort
        })
        .then((res) => {
          dataList.value = res.data.resource;
          pagination.value.total = res.data.total;
          pagination.value.current = res.data.pageIndex + 1;
          pagination.value.pageSize = res.data.pageSize;
        });
    };
    watch(
      () => formState.withJTP,
      (val) => {
        if (withoutJtpGradeOps && withJtpGradeOps) staffGradeOps.value = val === '0' ? withoutJtpGradeOps : withJtpGradeOps
        if (val === '0' && formState.gradeList.length) {
          formState.gradeList = handleJtpFilter(formState.gradeList)
        }
      },
      { immediate: true, deep: true }
    );
    return {
      readQueryFromBoard,
      queryDoSearch,
      exportClick,
      filterOption,
      dataList,
      columns,
      formState,
      skillNameOps,
      staffGradeOps,
      positionStatusOps,
      assignmentTypeOps,
      availabilityOps,
      formRef,
      resetForm,
      pagination,
      doSearch,
      tableHeight,
      handleOpenEmpInfo,
      isShowingEmpInfo,
      getId,
      // userDataList,
      closeEmpInfo,
      downloadClick,
      ...toRefs(state),
      rowSelection,
      backRmBoard,
      statusOps,
      taskTypeOps,
      taskTypeMap,
      handleOrgChange,
      isShowCollapseBtn,
      handleOpenTaskList,
      handleAddTask,
      handleNotesEdit,
      taskDrawerVisible,
      DrawerVisible,
      toDrawerVisible,
      taskData,
      filterItemsBtn,
      availabilityColumns,
      availabilityData,
      assignmentColumns,
      assignmentData,
      radioOptions,
      handleDateChange,
      roleOps,
      handleFilter,
      show,
      orgOpsObj,
      orgAllList,
      getSize,
      notesData,
      notesDrawerVisible,
      welcomedisabled,
      towelcome,
      welcomeVisible,
      handleWelcomeCancel,
      rowlist,
      handleTableChange,
      handleCancel,
      btnList,
      queryFormList,
      queryList,
      checkList,
      getOptions,
      getSelectChange,
      isShowMoreList,
      showMoreForm,
      moreBtnVisible,
      openFormSortBox,
      isShowFormSortBox,
      closeMoreForm,
      closeModal,
      moreSeachHanlder
    };
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (from.path == '/staffBoard') {
        if (Object.keys(vm.$route.query).length) {
          vm.readQueryFromBoard()
        }
      }
    })
  },
});
</script>
<style scoped lang="less">
.titleHeader {
  display: flex;
  justify-content: flex-end;
  margin-right: 35px;
}

.app-container {
  height: calc(100% - 48px);
  .btnRadio {
    width: 100%;
    display: flex;
      .ant-radio-button-wrapper {
        width: 50%;
        text-align: center;
      }
    }
  }

  .assignment-icon {
    background: rgba(24, 144, 255, 0.6);
    border-radius: 4px;
    font-size: 12px;
    line-height: 17px;
    color: #ffffff;
    padding: 2px 5px;
    margin-left: 5px;
  }

  .availability-icon {
    background: rgba(24, 144, 255, 0.6);
    border-radius: 2px;
    font-size: 16px;
    line-height: 22px;
    color: #505050;
    padding: 2px 8px;
  }

  .task-icon {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 10px;
    margin-right: 4px;
  }
  .explainIcon {
    :deep(.ant-form-item-label > label > .anticon) {
      vertical-align: baseline;
    }
  }
:deep(.dropdown){
  .ant-dropdown-menu-item{
    color: #3182CE;
    &.ant-dropdown-menu-item-disabled{
      color: #ccc;
    }
    &:hover{
      background: #E9F6FE;
    }
    span.ant-dropdown-menu-title-content{
      display: flex;
      height: 100%;
      line-height: 36px;
    }
    .anticon{
      display: inline-block;
      width: 24px;
    }
  }
}

:deep(.icon-font){
  display: inline-block;
  color: #3182CE;
  svg{
    display: inline-block;
    width: 100%;
    height: 100%;
  }
}
.buttons-wrap{
  :deep(.anticon){
    font-size: 20px;
  }
}
</style>
