<template>
  <a-drawer
    wrapClassName="task-drawer"
    width="38.5vw"
    :title="title"
    :visible="visible"
    @update:visible="(val) => $emit('update:visible', val)"
  >
    <div class="header">
      <div class="user">
        <div v-if="user">
          <div class="name">{{ user.fullName }}</div>
        </div>
      </div>
    </div>
    <div class="info" v-if="user">
      <div class="info-cell">
        Team: <span>{{ user.orgThreeTwo }}</span>
      </div>
      <div class="info-cell">
        Offering: <span>{{ user.orgFourOneOne }}</span>
      </div>
      <div class="info-cell">
        Grade: <span>{{ user.psGroup }}</span>
      </div>
      <div class="info-cell">
        GGID: <span>{{ user.ggid }}</span>
      </div>
    </div>
    <a-divider v-if="user" />
    <slot></slot>
    <div class="forms mt24">
      <a-form
        class="form"
        ref="formRef1"
        layout="vertical"
        :rules="rules"
        :model="formState"
      >
        <a-form-item label="Task Type" name="taskType">
          <a-select
            class="item"
            placeholder="请选择"
            allow-clear
            :disabled="readonly || !!task.id"
            :options="taskTypeOps"
            @change="handleTaskTypeChange"
            v-model:value="formState.taskType"
          />
        </a-form-item>
        <a-form-item label="开始时间" name="beginDate">
          <a-date-picker
            class="item"
            allow-clear
            type="date"
            placeholder="选择日期"
            valueFormat="YYYY-MM-DD"
            :disabled="readonly || beginDateDisabled"
            :disabledDate="disabledDate"
            v-model:value="formState.beginDate"
          />
        </a-form-item>
        <a-form-item label="小时数" name="hours">
          <a-input-number
            class="item"
            placeholder="请输入"
            :disabled="readonly || hoursDisabled"
            :step="0.1"
            :min="0.1"
            :max="8"
            v-model:value="formState.hours"
          />
        </a-form-item>
        <a-form-item label="Project Code" name="projectCode">
          <a-input
            class="item"
            placeholder="请输入"
            allow-clear
            :disabled="readonly"
            v-model:value="formState.projectCode"
          />
        </a-form-item>
      </a-form>
      <a-form
        class="form"
        ref="formRef2"
        layout="vertical"
        :rules="rules"
        :model="formState"
      >
        <a-form-item label="Task Detail" name="taskDetail">
          <a-select
            class="item"
            placeholder="请选择"
            allow-clear
            :options="taskDetailOps"
            :disabled="readonly || (!!task.id && task.taskType !== '13')"
            v-model:value="formState.taskDetail"
          />
        </a-form-item>
        <a-form-item label="结束时间" name="endDate">
          <a-date-picker
            class="item"
            allow-clear
            type="date"
            placeholder="选择日期"
            valueFormat="YYYY-MM-DD"
            :disabledDate="disabledDate"
            :disabled="readonly"
            v-model:value="formState.endDate"
          />
        </a-form-item>
        <a-form-item label="地点" name="location">
          <a-select
            class="item"
            placeholder="请搜索选择"
            allow-clear
            show-search
            :options="locationOps"
            :disabled="readonly"
            v-model:value="formState.location"
          />
        </a-form-item>
        <a-form-item label="PM" name="pm">
          <a-select
            class="item"
            placeholder="请搜索选择"
            allow-clear
            show-search
            :disabled="readonly"
            @change="handlePmChange"
            v-model:value="formState.pm"
          >
            <a-select-option
              v-for="item in pmOps"
              :key="item.id"
              :value="
                item.id +
                '|' +
                item.fullName +
                '/' +
                item.email +
                item.fullName.replace(', ', '') +
                item.fullName.replace(', ', ',')
              "
            >
              {{ item.fullName + "/" + item.email }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-form>
    </div>
    <a-form layout="vertical" :model="formState">
      <a-form-item label="Notes" name="notes">
        <a-textarea
          style="width: 88%"
          placeholder="请输入"
          :rows="4"
          :maxlength="100"
          :disabled="readonly"
          showCount
          v-model:value="formState.notes"
          :auto-size="{ minRows: 4, maxRows: 4 }"
          @keyup.enter="(e) => { e.stopPropagation() }"
        />
      </a-form-item>
    </a-form>
    <div class="bottom-btn">
      <a-space v-if="!readonly">
        <a-button type="primary" ghost @click="handleCancel">取消</a-button>
        <a-button type="primary" @click="handleSave">确定</a-button>
      </a-space>
    </div>
    <OverlapModal
      :data="overlapData"
      :taskType="formState.taskType"
      @confirm="handleConfirm"
      v-model:visible="overlapModalVisible"
    />
  </a-drawer>
  <ModalBox v-model="modelValue" :ok="handleOk" :cancel="handleCancel1" :content="content" />
</template>

<script>
import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  ref,
  watch,
} from "vue";
import { useGlobalPropertyHook } from "@/hooks/common.js";
import { dictionaryOptions } from "@/utils/common";
import moment from "moment";
import { message } from "ant-design-vue";
import OverlapModal from "@/components/OverlapModal.vue";
import ModalBox from '@/components/ModalBox'
export default defineComponent({
  name: "TaskDrawer",
  components: { OverlapModal, ModalBox },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
    userId: {
      type: String,
      default: "",
      required: true,
    },
    user: {
      type: Object,
      default: undefined,
    },
    task: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props, { emit }) {
    const { $api } = useGlobalPropertyHook();
    const formRef1 = ref();
    const formRef2 = ref();
    const formState = reactive({
      userId: props.userId,
      taskType: undefined,
      taskDetail: undefined,
      beginDate: "",
      endDate: "",
      hours: null,
      location: undefined,
      projectCode: "",
      pm: undefined,
      pmId: "",
      notes: "",
    });
    const rules = {
      taskType: [
        { required: true, message: "Task Type不能为空", trigger: "change" },
      ],
      taskDetail: [
        { required: true, message: "Task Detail不能为空", trigger: "change" },
      ],
      beginDate: [
        { required: true, message: "开始时间不能为空", trigger: "change" },
      ],
      endDate: [
        { required: true, message: "结束时间不能为空", trigger: "change" },
      ],
      hours: [
        {
          required: true,
          trigger: "change",
          validator: async (rule, value) => {
            if (
              !value ||
              isNaN(value) ||
              value?.toString().length > 3 ||
              value > 8 ||
              value <= 0
            ) {
              return Promise.reject("请输入0.1~8之间的数字,保留1位小数");
            }
          },
        },
      ],
      location: [
        { required: true, message: "地点不能为空", trigger: "change" },
      ],
    };
    const handleCancel = () => {
      emit("update:visible", false);
    };
    const overlapModalVisible = ref(false);
    const overlapData = ref({
      status: "prohibit",
      assignmentList: [],
      workTaskList: [],
      overlapCommandList: [],
      currentformData: {},
      demandList: [],
    });
    const annualAndOtherCode = ['12','13','06','07'];

    const modelValue = ref(false)
    const content = ref('')
    const modalData = ref()
    const handleOk = () => {
      const {resolve} = modalData.value
      resolve();
    }
    const handleCancel1 = () => {
      const {reject} = modalData.value
      reject();
    }
    const handleSave = () => {
      Promise.all([formRef1.value.validate(), formRef2.value.validate()])
        .then(() => {
          if (props.task.id) {
            return new Promise((resolve, reject) => {
              content.value = '是否确定修改并保存'
              modalData.value = {resolve, reject}
              modelValue.value = true

              /*Modal.confirm({
                title: "提示",
                icon: null,
                content: "是否确定修改并保存",
                okText: "确定",
                cancelText: "取消",
                onOk() {
                  resolve();
                },
                onCancel() {
                  reject();
                },
              });*/
            });
          } else {
            return Promise.resolve();
          }
        })
        .then(async() => {
          formState.pmEmail = ""
          formState.pmEncryptEmail = ""
          formState.pmName = ""
          if (formState.pm) {
            formState.pmName = formState.pm.match(/(?<=\|).*?(?=\/)/)[0];
          }
          try {
            const isOverlapRes = annualAndOtherCode.includes(formState.taskType) ? await $api.specialLeaveOverlap(formState):await $api.workTaskOverlap(formState);
            if(isOverlapRes.data.gatherFlag !== 'false'){
              overlapData.value = {
                status: isOverlapRes.data.gatherFlag,
                assignmentList: isOverlapRes.data.assignmentQueryPartCommandList || [],
                workTaskList: isOverlapRes.data.workTaskList || [],
                overlapCommandList: isOverlapRes.data.overlapCommandList || [],
                currentformData: {},
                demandList: isOverlapRes.data.demandList || [],
              };
              overlapModalVisible.value = true;
              return Promise.reject();
            }else {
              return Promise.resolve();
            }
          }catch (e){
            return Promise.reject();
          }
        })
        .then(() => {
          return addNewTask();
        })
        .then(() => {
          message.success("保存成功");
          emit("update:visible", false);
          emit("update");
        })
        .catch((error) => {
          console.log(error);
        });
    };
    const addNewTask = () => $api.addNewTask(formState);
    const handleConfirm = () => {
      addNewTask().then(() => {
        message.success("保存成功");
        emit("update:visible", false);
        emit("update");
      });
    };
    const handlePmChange = (val) => {
      formState.pmId = val?.split("|")[0];
    };
    const taskTypeOps = ref([]);
    const taskDetailOps = ref([]);
    const locationOps = ref([]);
    const pmOps = ref([]);
    const getOptions = () => {
      if (props.task.id) {
        $api.getWorkTaskComboBox().then((res) => {
          taskTypeOps.value = (res.data?.taskType || []).map(
            ({ dictCode, dictValue }) => ({
              value: dictCode,
              label: dictValue,
            })
          );
        });
        $api
        .getTaskDetail({
          conditionCommand: [props.task.taskType],
        })
        .then((res) => {
          taskDetailOps.value = (res.data || []).map(
            ({ dictCode, dictValue }) => ({
              value: dictCode,
              label: dictValue,
            })
          );
        });
      } else {
        $api.getWorkTaskComboBox().then((res) => {
          taskTypeOps.value = (res.data?.taskType || []).map(
            ({ dictCode, dictValue }) => ({
              value: dictCode,
              label: dictValue,
            })
          );
          taskDetailOps.value = (res.data?.taskDetail || []).map(
            ({ dictCode, dictValue }) => ({
              value: dictCode,
              label: dictValue,
            })
          );
        });
      }
      $api
        .getDictionaryList({
          params: {
            dictType: "city",
          },
        })
        .then((res) => {
          locationOps.value = dictionaryOptions(res.data.city);
          if (props.user && props.user.workLocation && locationOps.value) {
            let locationList = locationOps.value.filter(x => x.value == props.user.workLocation);
            if (locationList && locationList.length > 0) {
              formState.location = props.user.workLocation;
            }
          }
        });
      $api.findMatchingPM().then((res) => {
        pmOps.value = res.data;
        if (props.task.pmId) {
          let pm = pmOps.value.find(({ id }) => id === props.task.pmId);
          formState.pm =
            pm.id +
            "|" +
            pm.fullName +
            "/" +
            pm.email +
            pm.fullName.replace(", ", "") +
            pm.fullName.replace(", ", ",");
        }
      });
    };
    const hoursDisabled = computed(() =>
        ["07"].includes(
        formState.taskType
      )
    );
    const handleTaskTypeChange = (val) => {
      formState.taskDetail = undefined;
      $api
        .getTaskDetail({
          conditionCommand: [val],
        })
        .then((res) => {
          taskDetailOps.value = (res.data || []).map(
            ({ dictCode, dictValue }) => ({
              value: dictCode,
              label: dictValue,
            })
          );
        });
      if (hoursDisabled.value) {
        formState.hours = 8;
      } else {
        formState.hours = null;
      }
    };
    const disabledDate = (current) =>
      current < moment(props.user?.onboardDate || "1970-01-01") ||
      current > moment(props.user?.terminationDate || "9999-01-01");
    const beginDateDisabled = computed(() => props.task.id && moment() >= moment(props.task.beginDate));
    watch(
      () => props.userId,
      (val) => {
        if (val) formState.userId = val;
      }
    );
    onMounted(async () => {
      getOptions();
      if (props.task.id) {
        Object.assign(formState, props.task);
      }
    });
    return {
      formRef1,
      formRef2,
      formState,
      rules,
      handleCancel,
      handleSave,
      handlePmChange,
      handleTaskTypeChange,
      taskTypeOps,
      taskDetailOps,
      locationOps,
      pmOps,
      hoursDisabled,
      disabledDate,
      overlapModalVisible,
      overlapData,
      handleConfirm,
      beginDateDisabled,
      modelValue,
      content,
      handleOk,
      handleCancel1
    };
  },
});
</script>

<style scoped lang="less">
.task-drawer {
  .header {
    padding: 0 24px;
    display: flex;
    justify-content: space-between;
    .user {
      display: flex;
      align-items: center;
      font-size: 16px;
      line-height: 22px;
      .name {
        font-weight: 500;
        font-size: 24px;
        line-height: 34px;
        color: #505050;
      }
    }
  }
  .info {
    margin: 24px 0;
    padding: 24px;
    width: 100%;
    height: 108px;
    background: #fafafa;
    display: flex;
    flex-wrap: wrap;
    .info-cell {
      width: 50%;
      font-size: 16px;
      line-height: 22px;
      color: #2d3748;
      margin-bottom: 16px;
      span {
        margin-left: 16px;
        color: #999;
      }
    }
  }
  .forms {
    display: flex;
    .form {
      flex: 1;
      margin-right: 100px;
      .item {
        width: 240px;
      }
    }
  }
}
</style>
