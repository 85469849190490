<template>
  <a-drawer wrapClassName="drawer" width="64.6vw" :title="title">
    <p class="titletop">是否发送简历更新邮件提示？</p>
    <a-table :data-source="rowlist" :columns="columns" rowKey="id" :pagination="false">
    </a-table>
    <div class="feature-line bottom-btn">
      <a-button v-auth="['system:staffInfo:resumetemplate']" type="primary" @click="changeResumeTemplate">修改简历模板</a-button>
      <a-button style="margin-left:8px;" @click="handleCancel">取消</a-button>
      <a-button type="primary" style="margin-left:8px;" @click="handleConfirm">确定</a-button>
    </div>
    <div ref="drawerRef">
      <!-- 修改简历模板 -->
      <a-modal :getContainer="()=>$refs.drawerRef" width="29.2vw" :visible="modalvisible" @cancel="closeModal" :z-index="10001" centered>
        <template #title>
          <strong v-auth="['system:staffInfo:resumetemplate']" class="modal-title">修改模版</strong>
        </template>
        <template #footer>
          <div class="footerBtn">
            <a-button @click="closeModal" class="cancelBtn">取消 </a-button>
            <a-button @click="handleConfirm" type="primary" class="applyBtn">
              确定
            </a-button>
          </div>
        </template>
        <p>Dear xxx,</p>
        <a-textarea v-model:value="textarea" :auto-size="{ minRows: 1, }" showCount />
        <p>简历模板如附件，RMS上传简历地址：capgemini-staffing-system-web（登录后直接定位至上传简历页面）<br />
          要求：<br />
          1.概述部分需要详细，抓住重点，充分展现自己的技能和优势。<br />
          2.项目经验部分也要尽量丰富， 比如具体化职责，运用的技术，取得的成果，等等<br />
          凯捷模板简历是大家在凯捷及客户中间流通的名片，日常主要用于：<br />
          1. 项目上竞标、打单时进行团队实力展示；<br />
          2. 上项目时，有助于客户及新的项目组负责人了解大家；<br />
          3. 公司或团队项目有需求时，方便对大家进行项目推荐等<br />
          <br />
          BR<br />
          RM
        </p>
      </a-modal>
      <a-modal :getContainer="()=>$refs.drawerRef" width="29.2vw" :visible="Confirmvisible" @cancel="closeConfirmModal" :z-index="10001">
        <template #footer>
          <div class="footerBtn">
            <a-button @click="closeConfirmModal" class="cancelBtn">取消 </a-button>
            <a-button @click="handleConfirm2" type="primary" class="applyBtn">
              确定
            </a-button>
          </div>
        </template>
        <p class="txt">{{ txt }}</p>
      </a-modal>
    </div>
  </a-drawer>
</template>

<script>
import {
  defineComponent,
  onMounted,
  ref,
  computed
} from "vue";
import { useGlobalPropertyHook } from "@/hooks/common.js";
import { message } from "ant-design-vue";

export default defineComponent({
  name: "Drawer",
  components: {},
  props: {
    title: {
      type: String,
      default: "",
    },
    rowlist: {
      type: Array,
      default: () => ([]),
    },
  },
  setup(props, { emit }) {
    const columns = [
      {
        title: "Name",
        dataIndex: "fullName",
        ellipsis: true,
        width: 100,
      },
      {
        title: "GGID",
        dataIndex: "ggid",
        ellipsis: true,
        width: 100,
      },
      {
        title: "简历最近一次更新时间",
        dataIndex: "cvUpdateDate",
        ellipsis: true,
        width: 150,
      },
      {
        title: "简历最近一次提醒时间",
        dataIndex: "cvReminderDate",
        ellipsis: true,
        width: 150,
      },
      {
        title: "简历数量",
        dataIndex: "cvNumber",
        ellipsis: true,
        width: 100,
      },
    ]
    const { $api } = useGlobalPropertyHook();
    const ids = computed(() => {
      const arr = []
      props.rowlist.forEach(item => {
        arr.push(item.staffId)
      });
      return arr
    })

    //修改模板开关
    const modalvisible = ref(false)
    const Confirmvisible = ref(false)
    const textarea = ref('因您的凯捷模板简历长时间未更新或缺少，请在收到邮件后的5个工作日内，完成凯捷模板简历（4个文件）的更新并上传至RMS，谢谢。')
    const changeResumeTemplate = () => {
      modalvisible.value = true
    }
    const closeModal = () => {
      modalvisible.value = false
    }
    const closeConfirmModal = () => {
      Confirmvisible.value = false
    }
    //关闭抽屉
    const handleCancel = () => {
      emit('handleCancel')
    }
    const txt = ref('')
    const handleConfirm = () => {
      modalvisible.value = false
      const data = { ids: ids.value, 'content': textarea.value }
      $api.checkCvReminderTime(data).then(res => {
        if (res.data?.length > 0) {
          txt.value = res.data[0]
          Confirmvisible.value = true
        } else {
          message.success("发送成功");
        }
      })
    }
    const handleConfirm2 = () => {
      const data = { ids: ids.value, 'content': textarea.value }
      $api.sendCvReminderToConsultant(data).then(() => {
        Confirmvisible.value = false
        message.success("发送成功");
      })

    }
    onMounted(() => {
      console.log(props.rowlist);
    });
    return {
      columns,
      changeResumeTemplate,
      handleCancel,
      handleConfirm,
      modalvisible,
      Confirmvisible,
      closeModal,
      textarea,
      closeConfirmModal,
      handleConfirm2,
      txt
    };
  },
});
</script>

<style scoped lang="less">
@import "~@/style/aModal.less";
.feature-line {
  text-align: right;
}

.titletop {
  text-align: center;
  font-size: 14px;
  font-weight: 800;
  margin-top: 15px;
}

.txt {
  text-align: center;
}
</style>
